

























































































































import { Component, Vue } from "vue-property-decorator";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
import mediciones_caseraModule from "@/store/modules/visualizacion_dieta/mediciones_casera-module";
import { mediciones_casera } from "@/shared/dtos/visualizacion_dieta/mediciones_casera";
import { ssmMessageService } from "@/shared/services/message-service";
import { alimento } from "@/shared/dtos/visualizacion_dieta/alimento";
@Component({
  $_veeValidate: { validator: "new" },
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
  },
})
export default class admin_alimento_edit extends Vue {
  public medicion = new mediciones_casera({ lista_compra: true });
  public dialog: boolean = false;
  public dialog_importar: boolean = false;
  public search: string = "";
  public created() {
    alimentoModule.getalimentos();
    mediciones_caseraModule.getmediciones_caseras_by_alimento(
      Number.parseInt(this.$route.params.id)
    );
  }

  public get alimento() {
    return alimentoModule.alimentos.find(
      (x) => Number.parseInt(x.id) === Number.parseInt(this.$route.params.id)
    );
  }

  public get source() {
    return mediciones_caseraModule.mediciones_caseras;
  }

  public get headers() {
    return [
      {
        text: "Medida",
        align: "start",
        value: "medida",
      },
      { text: "Cantidad minima", value: "cantidad_minima" },
      { text: "Cantidad maxima", value: "cantidad_maxima" },
      { text: "Lista Compra", value: "lista_compra" },
      { text: "", value: "acciones" },
    ];
  }

  public get source_alimentos() {
    return alimentoModule.alimentos;
  }

  public get headers_alimentos() {
    return [
      {
        text: "Alimento",
        value: "nombre",
      },
      { text: "", value: "acciones" },
    ];
  }

  public cancelar() {
    this.medicion = new mediciones_casera({ lista_compra: true });
    this.dialog = false;
    mediciones_caseraModule.getmediciones_caseras_by_alimento(
      Number.parseInt(this.$route.params.id)
    );
  }
  public aceptar() {
    if (this.medicion.id === null) {
      this.medicion.id_alimento = Number.parseInt(this.$route.params.id);
      mediciones_caseraModule
        .guardarmediciones_casera(this.medicion)
        .then(() => this.cancelar());
    } else {
      mediciones_caseraModule
        .modificarmediciones_casera(this.medicion)
        .then(() => this.cancelar());
    }
  }
  public edit(e: mediciones_casera) {
    this.medicion = e;
    this.dialog = true;
  }
  public eliminar(e: mediciones_casera) {
    ssmMessageService.confirm(
      "¿Seguro que quieres eliminar " + e.medida + "?",
      "ELIMINAR",
      (r) => {
        if (r) {
          e.id_alimento = Number.parseInt(this.$route.params.id);
          mediciones_caseraModule
            .eliminarmediciones_casera_vinculo(e)
            .then(() => this.cancelar());
        } else {
          this.cancelar();
        }
      }
    );
  }
  public Showimportar() {
    this.dialog_importar = true;
  }
  public async importar(alimento: alimento) {
    await mediciones_caseraModule.getmediciones_caseras_by_alimentoOtherVariable(
      alimento.id
    );
    for (
      let i = 0;
      i < mediciones_caseraModule.mediciones_caserasOtherVariable.length;
      i++
    ) {
      var nueva_medida = new mediciones_casera(
        mediciones_caseraModule.mediciones_caserasOtherVariable[i]
      );
      if (nueva_medida.lista_compra) {
        nueva_medida.id = 0;
        nueva_medida.id_alimento = Number.parseInt(this.$route.params.id);
        await mediciones_caseraModule.guardarmediciones_casera(nueva_medida);
      }
    }
    mediciones_caseraModule.getmediciones_caseras_by_alimento(
      Number.parseInt(this.$route.params.id)
    );
    this.dialog_importar = false;
  }
}
