import { store } from '@/store/store';
import { mediciones_casera } from '@/shared/dtos/visualizacion_dieta/mediciones_casera';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'mediciones_caseraModule',
    store,
    dynamic: true
})
class mediciones_caseraModule extends VuexModule {
    public mediciones_caseras: mediciones_casera[] = [];
    public mediciones_caserasOtherVariable: mediciones_casera[] = [];
    public mediciones_casera: mediciones_casera = new mediciones_casera();

    @Action({ commit: 'onGetmediciones_caseras' })
    public async getmediciones_caseras() {
        return await ssmHttpService.get(API.mediciones_caseras);
    }

    @Action({ commit: 'onGetmediciones_caseras' })
    public async getmediciones_caseras_by_alimento(id: number) {
        return await ssmHttpService.get(API.mediciones_caseras + '/alimento_mediciones/' + id);
    }
    @Action({ commit: 'onGetmediciones_caserasOtherVariable' })
    public async getmediciones_caseras_by_alimentoOtherVariable(id: number) {
        return await ssmHttpService.get(API.mediciones_caseras + '/alimento_mediciones/' + id);
    }


    @Action({ commit: 'onGetmediciones_casera' })
    public async getmediciones_casera(id: any) {
        return await ssmHttpService.get(API.mediciones_caseras + '/' + id);
    }

    @Action
    public async guardarmediciones_casera(mediciones_casera: mediciones_casera) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        return await ssmHttpService.post(API.mediciones_caseras + '/medidas', mediciones_casera.toJson());

    }

    @Action
    public async modificarmediciones_casera(mediciones_casera: mediciones_casera) {
        return await ssmHttpService.put(API.mediciones_caseras + '/' + mediciones_casera.id, mediciones_casera);
    }

    @Action
    public async eliminarmediciones_casera(mediciones_casera: mediciones_casera) {
        return await ssmHttpService.delete(API.mediciones_caseras + '/' + mediciones_casera.id, null, false);
    }
    @Action
    public async eliminarmediciones_casera_vinculo(mediciones_casera: mediciones_casera) {
        return await ssmHttpService.delete(API.mediciones_caseras + '/' + mediciones_casera.id_alimento + '/' + mediciones_casera.id, null, false);
    }

    @Mutation
    public onGetmediciones_caseras(res: mediciones_casera[]) {

        this.mediciones_caseras = res ? res.map((x) => new mediciones_casera(x)) : []
    }
    @Mutation
    public onGetmediciones_caserasOtherVariable(res: mediciones_casera[]) {
        this.mediciones_caserasOtherVariable = res ? res.map((x) => new mediciones_casera(x)) : []
    }
    @Mutation
    public onGetmediciones_casera(res: mediciones_casera) {
        this.mediciones_casera = new mediciones_casera(res);
    }

}
export default getModule(mediciones_caseraModule);